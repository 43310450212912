
















































































































































































.landingPage {
  input[name="subdomain"]{
    max-width: 150px;
    border-radius: 7px !important;
  }
  input[type="file"].form-control{
    border: 0;
    padding-left: 0;
  }
}
